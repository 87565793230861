import React, { useState, useEffect } from 'react';

function DarkModeSwitcher() {
  let storedDarkMode = localStorage.getItem('darkMode');
  const [isDarkMode, setIsDarkMode] = useState(!!JSON.parse(storedDarkMode));

useEffect(() => {
  let circle = document.getElementById('dark_mode_circle');
  let root_var = document.querySelector(':root');
  if (isDarkMode) {
    circle.classList.add('dark-mode-circle');
    circle.classList.remove('light-mode-circle');
    root_var.style.setProperty('--dark', 'white');
    root_var.style.setProperty('--light', '#181818');
  } else {
    circle.classList.add('light-mode-circle');
    circle.classList.remove('dark-mode-circle');
    root_var.style.setProperty('--dark', '#181818');
    root_var.style.setProperty('--light', 'white');
  }

  localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
}, [isDarkMode]);

const toggleDarkMode = () => {
  setIsDarkMode(!isDarkMode);
};


  return (
    <div className="d-flex align-items-center justify-content-center switcher-container" id='switcher' onClick={toggleDarkMode}>
        <div className="dark-mode dark-mode-dark">
          <div className="switch-circle" id="dark_mode_circle"></div>
        </div>
    </div>
  );
}

export default DarkModeSwitcher;
