import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

export default function ServiceComponent({data}) {
    const location = useLocation();
    const service = location.state.service;
    const [navbarHeight, setNavbarHeight] = useState(0);
  const sectionRef = useRef(null);

  useEffect(() => {
    // Get the height of the navbar
    const navbar = document.querySelector('.navbar'); // Replace with your actual selector
    if (navbar) {
      const height = navbar.clientHeight;
      setNavbarHeight(height);
    }

    // Add margin-top to the section based on the navbar height
    if (sectionRef.current) {
      sectionRef.current.style.marginTop = `${navbarHeight}px`;
    }
  }, [navbarHeight]);
  return (
    <div ref={sectionRef}>
        <img style={{width: '100%', height: '70vh'}} src={`/assets/images/services/${service.banner}`} alt='Not found'/>
        <section className='py-5 bg-white'>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h2 className='mb-0 pb-3 text-dark'>{service.title}</h2>
                        <p>{service.description}</p>
                    </div>
                    {service.content.map(content => {
                      return (
                          <div className='col-md-4 text-center mb-5'>
                              <div className='bg-danger p-5 h-100 rounded-3'>
                                  <h3 className='text-black mb-2'>{content.title}</h3>
                                  <p className='text-white mb-0'>{content.description}</p>
                              </div>
                      </div>
                      )
                    })}

                    <div className='d-flex justify-content-center'>
                        <Link to='/our-plans' className='btn btn-lg bg-danger text-white'>CHECK OUR PLANS</Link>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
