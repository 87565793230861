
import React, { useRef, useState } from 'react';
import axios from 'axios';

export default function AboutComponent({ aboutus, title }) {
    const emailRef = useRef();
    const nameRef = useRef();
    const messageRef = useRef();
    const [errors, setErrors] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);

    const handleSubmit = async (e) => {
        const nameValue = nameRef.current.value;
        const emailValue = emailRef.current.value;
        const messageValue = messageRef.current.value;

        e.preventDefault();
        try {
            await axios.post('https://yol-eg.com/backend/api/contact', {
                email: emailValue,
                name: nameValue ,
                message:  messageValue
            });

            setIsSuccess(true);
            setErrors({});
            window.scrollTo(0, 0);
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
                window.scrollTo(0, 0);
            } else {
                console.error(error);
            }
        }
    }


  return (
    <div className='bg-white'>
        <div className='container'>
            <div style={{ marginTop :"6%" }} className='container'>
                <div  className="contact_banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="banner-content">
                                    <h2 className="font-weight-black text-dark">Let's Talk</h2>
                                    <p>You can <a href="mailto:info@yol-eg.com" className="js-scroll-trigger text-dark">send us an email</a> or simply fill out the enquiry form and we will get back to you as soon as possible.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='py-5' id="mt_contact_section">
                    <div className="clearfix"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 float-left rt_form_sec">
                                {Object.keys(errors).length > 0 && (
                                    <div className="alert alert-danger" role="alert">
                                        <ul>
                                            {Object.keys(errors).map((field, index) => (
                                                <li className='ps-0 text-white' key={index}>{errors[field][0]}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                {isSuccess && (
                                    <div className="alert alert-success fs-6" role="alert">
                                        Form submitted successfully we will catch you soon!
                                    </div>
                                )}

                                <form id="contact_form" onSubmit={handleSubmit}>
                                    <input type="text" ref={nameRef} placeholder="Your name" />
                                    <input type="email" ref={emailRef} placeholder="Your email" />
                                    <textarea cols="30" rows="5" ref={messageRef} placeholder="Your Message"></textarea>
                                    <button className="see_more nav-link light" type="submit" id="submit-btn">
                                        <span className="bottom rounded-2"></span>
                                        <span className="top rounded-2">
                                            <span className="label">SEND MESSAGE</span>
                                            <span className="button-border button-border-left rounded-2"></span>
                                            <span className="button-border button-border-top"></span>
                                            <span className="button-border button-border-right"></span>
                                            <span className="button-border button-border-bottom"></span>
                                        </span>
                                        <span className="mt_load">
                                            <span></span>
                                        </span>
                                    </button>
                                </form>
                            </div>

                            <div className="col-lg-5 col-md-6 float-right rt_address_info">
                                <h4 className="font-weight-black text-dark">Cairo-Egypt</h4>
                                <p>Villa 107, Street 58, District No 2, New Cairo 1, Cairo Governorate 4723332, Cairo Governorate 11835</p>
                                <div className="email-phone">
                                    <ul>
                                        <p className='text-dark'><span> E:</span> &nbsp;&nbsp; <a href="mailto:info@yol-eg.com" className='text-dark'>info@yol-eg.com</a></p>
                                        <p className='text-dark'><span> T:</span> &nbsp;&nbsp; <a href="tel:+01289299998" className='text-dark'>012 892 999 98</a></p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
  );
}
