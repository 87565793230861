import React, { useRef, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

export default function Footer() {
    const subsRef = useRef();
    const [subscribeErrors, setSubscribeErrors] = useState({});
    const [isSubscribeSuccess, setSubscribeIsSuccess] = useState(false);
    const handleSubmit = async (e) => {
        
        const subsValue = subsRef.current.value;

       e.preventDefault();
        try {
            await axios.post('https://yol-eg.com/backend/api/subscribe', {
                email: subsValue
            });
            setSubscribeIsSuccess(true);
            setSubscribeErrors({});
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setSubscribeErrors(error.response.data.errors);
            } else {
                console.error(error);
            }
        }
    }

  return (
    <div className="rt_footer bg-white">
        <div className="container">
            <div className="row">
                <div className="col-md-6 text-left footer_links">
                    <ul className="list-style display-inline-b">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About Us</Link></li>
                        <li><Link to="/our-plans">Our Plans</Link></li>
                        <li><Link to="/contact">Contact Us</Link></li>
                    </ul>
                    <ul className="footer-social">
                        <li>
                            <a href="https://twitter.com/yol_2023?t=B68mdREfI98DNs8D2dbehQ&s=09" target="_blank" rel="noreferrer"><i className="fs-5 me-2 fa-brands fa-x-twitter"></i></a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/profile.php?id=61550220747073&mibextid=2JQ9oc" target="_blank" rel="noreferrer"><i className="fs-5 me-2 fa-brands fa-facebook"></i></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/a2zagency-eg/" target="_blank" rel="noreferrer"><i className="fs-5 me-2 fa-brands fa-linkedin"></i></a>
                        </li>
                        <li>
                            <a href="https://instagram.com/yol_eg.co?utm_source=qr&igshid=MzNlNGNkZWQ4Mg==" target="_blank" rel="noreferrer"><i className="fs-5 me-2 fa-brands fa-instagram"></i></a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCrrGTeFk3AitDV7GRz5EhjA" target="_blank" rel="noreferrer"><i className="fs-5 me-2 fa-brands fa-youtube"></i></a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/@yol_eg.co?_t=8fNbIl0DwMH&_r=1" target="_blank" rel="noreferrer"><i className="fs-5 me-2 fa-brands fa-tiktok"></i></a>
                        </li>
                        <li>
                            <a href="https://goo.gl/maps/Vs1ZbVtoRSuN3uyVA" target="_blank" rel="noreferrer"><i className="fs-5 fa-solid fa-location-dot"></i></a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-6 text-right">
                    <div className="newsletter_form">
                    
                        <form onSubmit={handleSubmit}>
                            <div className='position-relative'>
                                <input type="text" ref={subsRef} placeholder="Email address" className="form-control rounded-2" />
                                <button type='submit' className="animated_btn rounded-2">
                                    subscribe
                                    <span>
                                    <i className="fa-solid fa-arrow-right"></i>
                                    </span>
                                </button>
                            </div>
                        </form>

     
                        <p><small>From Vision to Success, We draw the Way.</small></p>
                    </div>
                    {Object.keys(subscribeErrors).length > 0 && (
                        <p className='text-danger fs-6'>
                            {subscribeErrors["email"][0]}
                        </p>
                    )}

                    {isSubscribeSuccess && (
                        <p className="text-success fs-6">
                            Thank you for your subscription!
                        </p>
                    )}
                </div>
            </div>
        </div>
        <div style={{float: 'left'}} class="call-buton"><a class="cc-calto-action-ripple" href="tel:201289299998"><i class="fa fa-phone"></i>
            </a>
        </div>
    </div>
  )
}
