import React, { useEffect } from 'react'
import ServiceComponent from '../../components/Service/ServiceComponent'
import { useParams } from 'react-router-dom';

export default function Service() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ServiceComponent />
    </>
  )
}
