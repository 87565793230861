import React, { useEffect, useRef, useState } from 'react'
import PlansComponent from '../../components/Plans/PlansComponent'

export default function Plans() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [navbarHeight, setNavbarHeight] = useState(0);
  const sectionRef = useRef(null);

  useEffect(() => {
    // Get the height of the navbar
    const navbar = document.querySelector('.navbar'); // Replace with your actual selector
    if (navbar) {
      const height = navbar.clientHeight;
      setNavbarHeight(height);
    }

    // Add margin-top to the section based on the navbar height
    if (sectionRef.current) {
      sectionRef.current.style.marginTop = `${navbarHeight}px`;
    }
  }, [navbarHeight]);

  const [plans, setPlans] = useState([
    {
      id: 1,
      title: "ADVANCED",
      features: [
        "Social Media Strategy",
        "Content Strategy & Scheduling",
        "8 Posts, 4 stories, 1 Reel",
        "Brand Identity (Name, Logo, Slogan)",
        "Social Media Buying Plan",
        "Social Media Buying",
        "Management of 2 Social Media Platform (Account Management)",
        "2 Hours of Engagement Per Week (Consulting)",
        "2 Hours meeting Per Month (30 Min per Week)",
        "Monthly Reports"
      ],
      price: "8,000 EGP",
      recommended: false
    },
    {
      id: 2,
      title: "PRO",
      features: [
        "Social Media Strategy",
        "Content Strategy & Scheduling",
        "15 Posts, 10 stories, 2 Reel",
        "ReBranding",
        "Website (UI & UX)",
        "Social Media Buying Plan",
        "Social Media Buying",
        "E-Commerce Development",
        "Management of 3 Social Media Platform (Account Management)",
        "3 Hours of Engagement Per Week (Consulting)",
        "3 Hours meeting Per Month (40 Min per Week)",
        "Monthly Reports"
      ],
      price: "10,000 EGP",
      recommended: true
    },
    {
      id: 3,
      title: "PREMIUM",
      features: [
        "Social Media Strategy",
        "Content Strategy & Scheduling",
        "20 Posts, 15 stories, 3 Reel",
        "ReBranding",
        "Website (UI & UX): (Host and Domain Excluded)",
        "Boost Sales",
        "SEO",
        "Social Media Buying Plan",
        "Social Media Buying",
        "Google Buying",
        "E-Commerce Development",
        "Management of all Social Media Platform (Account Management)",
        "3 Hours of Engagement Per Week(Consulting)",
        "3 Hours meeting Per Month (30 Min per Week)",
        "Monthly Reports"
      ],
      price: "17,000 EGP",
      recommended: false
    },
  ]);

  return (
    <div ref={sectionRef}>
      <PlansComponent plans={plans} />
    </div>
  )
}
