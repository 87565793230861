import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Navbar from './components/Navbar/Navbar';
import './styles/main.css';
import Footer from './components/Footer/Footer';
import Services from './pages/Services/Services';
import Service from './pages/Service/Service';
import Plans from './pages/Plans/Plans';
import DarkModeSwitcher from './components/DarkMode/DarkModeSwitcher';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/our-plans" element={<Plans />} />
        <Route path="/services/:name" element={<Service />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer/>
      <DarkModeSwitcher />
    </Router>
  );
}

export default App;
