import React from 'react';

export default function HeaderComponent() {
  return (
          <>
            <div className='py-7' style={{ backgroundImage: 'url(/assets/images/banner-website.webp)', height:  '90vh', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
              <div className='container h-100 d-flex align-items-center'>
                <div>
                  <h2 className='text-white'>From Vision to Success, We draw the Way</h2>
                  <p className='text-white'>At YOL, We are specialized in driving growth and maximizing the potential of your brand. With our strategic approach, creative expertise, and data-driven solutions, we are here to take your marketing efforts to the next level.</p>
                </div>
              </div>
            </div>
              
          </>
  );
}
