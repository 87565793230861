import React from 'react'
import Icon from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';
import { mdiCloseCircle } from '@mdi/js';

export default function PlansComponent({plans}) {

  return (
    <div className='bg-white'>
        <div className='container'>
            
        <div className="row py-5">
        {plans.map(plan => {
            return (
                <div className="col-lg-4" key={plan.id}>
                    <div className="pricing-box mt-4">
                        <h4 className="text-dark text-center fs-2 fw-bolder" style={{fontWeight: '900 !important', fontStyle: 'italic'}}>{plan.title}</h4>
                        <h4 className="text-center text-red"><span className="plan pl-3 fs-4 text-red">{plan.price} </span> <span className='fs-6 fw-light text-red'>/ Month</span></h4>


                        <div className="mt-4 pt-2">
                            {plan.features.map(feature => {
                                return (
                                    <div className='mb-3'>
                                        <i className="fa-solid fa-check"></i> <span className="fs-5 text-dark"> {feature}</span>
                                    </div>
                                )
                        })}

                        </div>

                        <div className="pricing-plan mt-4 pt-2 text-center">
                            <h4 className="bg-danger p-1  rounded-3"><span className="plan pl-3 fs-4 text-white">{plan.price} </span> <span className='fs-6 fw-light text-white'>/ Month</span></h4>
                            {/* <p className="text-muted mb-0 text-dark">Per Month</p> */}
                        </div>
                    </div>
                </div>
            )
            })}
        </div>
        </div>
    </div>
  )
}
