import React from 'react'

export default function WhyUsComponent({why_us}) {
  return (
    <div className='pt-5 py-md-5 bg-white'>
      <div className='text-center mb-5'>
          <h2 className='m-0 text-dark'>WHY US</h2>
      </div>
      <div className='container text-center'>
        <div className='row'>
        {why_us.map(record => {
            return (
                <div className='col-md-4 mb-mobile-10'>
                    <div className='position-relative rounded-4 py-5 px-3 bg-danger h-100'>
                        {/* <div className='why-us-img position-absolute'> */}
                            <img className='w-100' src={`/assets/images/why-us/${record.img}`} alt="Test" />
                        {/* </div> */}
                        <div className='mt-3'>
                            <h3 className='text-black mb-2'>{record.title}</h3>
                            <p className='text-white mb-0'>{record.content}</p>
                        </div>
                    </div>
                </div>
            )
        })}
        </div>
      </div>
    </div>
  )
}
