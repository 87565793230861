import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function ClientsCarouselComponent({clients}) {
  return (
    <div className='py-5 bg-white'>
        <div className='container'>
            <div className='pb-5 text-center'>
            <div className="container">
                <div className="row">
                    <div className="banner-wrapper">
                        <div className="banner-caption wow fadeInUp" data-wow-delay="0.1">
                            <h2 className='m-0 text-dark'>Our Clients</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <OwlCarousel className='owl-theme' loop margin={10} nav={true} dots={false} mouseDrag={true} items={1} touchDrag={true} responsive={{ 768: { items: 2 }, 992: { items: 3 } }}>
            {clients.map(client => {
                return (
                    <div className='item d-flex justify-content-center' key={client.id}>
                        <img className='w-50 rounded-circle' style={{height: '200px'}} src={`/assets/images/clients/${client.img}`} alt="Not Found"/>
                    </div>
                )
            })}
        </OwlCarousel>

        </div>
    </div>
  )
}
