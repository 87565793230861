import React from 'react'
import { Link } from "react-router-dom";

export default function ServicesComponent({services}) {
  return (
    <div style={{ backgroundImage: 'url(/assets/images/we-do.webp)', height:  'auto', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <div className='py-5 pb-0 text-center'>
          <div className="container">
              <div className="row">
                  <div className="banner-wrapper">
                      <div className="banner-caption wow fadeInUp" data-wow-delay="0.1">
                          <h1 className='m-0 text-white'>WE DO</h1>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div  id="mt_portfolio" className="gallery-section gallery-page portfolio_col_2">
          <div className="container">
              <div className="row">
                  <div className="isotopeContainer">
                  {services.map(service => {
                    return (
                      <div key={service.id} className="portfolio_grid no-padding isotopeSelector">
                          <figure className="portfolio_item">
                                <Link className='fs-3' to={`/services/${service.link}`} state={{ service }}>
                                    <img className="services-img" src={`/assets/images/services/${service.img}`} alt="Portfolio" />
                                </Link>
                          </figure>
                      </div>)
                  })}
                    </div>
                  </div>
          </div>
      </div>
    </div>
  )
}
