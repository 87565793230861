import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";

export default function Navbar() {
  const [services, setServices] = useState([
    {
        id: 1,
        title: "Digital Marketing",
        description: "We help you expand your reach, generate interest and excitement about your products, and grow your business.",
        content: [
            { 
                title: "Comprehensive Strategy",
                description: "We customize a unique digital marketing plan for your brand based on your objectives, target audience, and competition."
            },
            { 
                title: "SEO",
                description: "We enhance your website's visibility and search rankings through keyword research, on-page optimization, content creation, and high-quality backlinks."
            },
            { 
                title: "Content Creation",
                description: "Our creative team produces engaging content that resonates with your audience."
            },
            { 
                title: "PPC Advertising",
                description: "Data-driven campaigns drive qualified traffic to your website, boosting conversions and maximizing ROI."
            },
            { 
                title: "Analytics",
                description: "We use advanced analytics to track performance and provide reports on key metrics like website traffic, engagement, conversions, and ROI."
            },
            { 
                title: "Email Marketing",
                description: "Sending promotional emails to subscribers."
            }
        ],
        link: "digital-marketing",
        img: "1.webp",
        banner: "digital-marketing.webp"
    },
    {
        id: 2,
        title: "Marketing Plans & Strategies",
        description: "We understand your brand's products and services, business objectives and target audience. It’s important to figure out the strategy that’s right for you. A marketing strategy will detail the advertising, outreach, and PR campaigns to be carried out by a firm, including how the company will measure the effect of these initiatives. The marketing plan will include market research to support pricing decisions and new market entries, certain demographics and geographic areas, platform selection for product and service promotion.",
        content: [
            {
                title: "Market Analysis",
                description: "We dive deep into your industry, target market, and competitors to gain insights into trends and consumer behavior."
            },
            {
                title: "Audience Segmentation",
                description: "We identify and segment your target audience based on demographics, interests, and more."
            },
            {
                title: "Multi-Channel Approach",
                description: "We use various channels like social media, email, content, SEO, and paid advertising to create integrated strategies."
            },
            {
                title: "Compelling Storytelling",
                description: "We craft a charm brand narrative through engaging content and visuals that resonate with your audience."
            },
            {
                title: "Continuous Optimization",
                description: "We monitor campaign performance, analyze data, and make improvements."
            },
            {
                title: "Measurable ROI",
                description: "Our strategies are designed for measurable results, allowing you to track marketing effectiveness."
            }
        ],
        link: "marketing-plans-and-strategies",
        img: "6.webp",
        banner: "strategy.webp"
    },
    {
        id: 3,
        title: "Branding & Corporate Identity",
        description: "At YOL, we understand the power of branding and corporate identity in shaping your brand's perception and driving business success. Through our strategic approach, creative excellence, and attention to detail, we create brands that make a lasting impact.",
        content: [],
        link: "branding-and-corporate-identity",
        img: "2.webp",
        banner: "graphic-design.webp"
    },
    {
        id: 4,
        title: "Web & App Development",
        description: "We create the best online solutions to turn your ideas into reality. All our solutions are the most effective and designed to fit your exact requirements. We work closely with clients to fully understand their company and their ambitions.",
        content: [
            {
                title: "Custom Web Development",
                description: "We create unique, responsive, and scalable websites customized to your brand."
            },
            {
                title: "Mobile App Development",
                description: "Enhance your brand's reach with mobile apps that ensure a smooth user experience."
            },
            {
                title: "Cross-Platform Compatibility",
                description: "We make sure your websites and apps work seamlessly on various platforms."
            },
            {
                title: "Quality Assurance",
                description: "We prioritize quality with careful testing to address issues before launch."
            },
            {
                title: "Ongoing Support",
                description: "We provide continuous support and maintenance for your digital assets."
            },
            {
                title: "Database Management",
                description: " We work with database management systems to design and implement efficient data storage and retrieval mechanisms."
            }
        ],
        link: "web-and-app-development",
        img: "3.webp",
        banner: "web-dev.webp"
    },
    {
        id: 5,
        title: "Printing & Packing",
        description: "We offer comprehensive printing and packaging services that elevate your brand's visual impact and help you stand out from the competition. From eye-catching printed materials to attractive packaging solutions, we provide the tools you need to make a lasting impression.",
        content: [],
        link: "printing-and-packing",
        img: "4.webp",
        banner: "printing.webp"
    },
    {
        id: 6,
        title: "Event planning",
        description: "At YOL, we are passionate about delivering exceptional events that exceed your expectations. With our expertise in concept development, venue selection, event design, vendor management, marketing, on-site coordination, and post-event evaluation, we are dedicated to creating memorable occasions customized to your needs.",
        content: [],
        link: "event-planning",
        img: "5.webp",
        banner: "event-planing.webp"
    },
    {
        id: 7,
        title: "Social Media Marketing",
        description: "We help you hold this vast audience to expand your brand's reach. We engage with your audience, build brand awareness, and drive meaningful interactions that result in increased brand loyalty and conversions.",
        content: [],
        link: "social-media-marketing",
        img: "9.webp",
        banner: "Social-Media-Marketing.webp"
    },
    {
        id: 8,
        title: "Marketing Consultation",
        description: "At YOL, we offer comprehensive marketing consultation services to help businesses like yours achieve their goals. Our team of seasoned marketing experts provides customized solutions.",
        content: [],
        link: "marketing-consultation",
        img: "8.webp",
        banner: "Marketing-Consultation.webp"
    },
    {
        id: 9,
        title: "Offline Marketing",
        description: "We understand the power of offline marketing to create personal connections and drive brand recognition with our expertise in print advertising, direct mail, events and trade shows, outdoor advertising, promotional products, sponsorships, partnerships, networking, and community engagement.",
        content: [],
        link: "offline-marketing",
        img: "7.webp",
        banner: "offline-marketing.webp"
    }
]);


const [menuOpen, setMenuOpen] = useState(false);
const menuButtonRef = useRef(null);
const location = useLocation();

const toggleMenu = () => {
  setMenuOpen(!menuOpen);
};

const closeMenu = (event) => {
  if (menuButtonRef.current && !menuButtonRef.current.contains(event.target)) {
    setMenuOpen(false);
  }
};

useEffect(() => {
  setMenuOpen(false);
  document.getElementById('dropdown').classList.remove('show')
}, [location.pathname]);

useEffect(() => {
  document.addEventListener('click', closeMenu);
  return () => {
    document.removeEventListener('click', closeMenu);
  };
}, []);

const handleServicesClick = (event) => {
  event.stopPropagation();
  setMenuOpen(true);
};

return (
  <>
    <div className="loading">
        <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
        </div>
    </div>
    <nav className="navbar navbar-expand-md navbar-light bg-white py-3" aria-label="Fourth navbar example">
      <div className="container">
        <div className="navbar-brand">
          <div className="logo">
            <Link to="/">
              <img src="/assets/images/logo.webp" alt="Logo" />
            </Link>
          </div>
        </div>
        <button className="navbar-toggler" type="button" ref={menuButtonRef} onClick={toggleMenu} data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fa-solid fa-bars text-dark fs-2"></i>
        </button>

        <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarsExample04">
          <ul className="navbar-nav ms-auto mb-md-0">
            <li className="nav-item ps-2 active"><Link className='nav-link text-dark' to="/">Home</Link></li>
            <li className="nav-item ps-2"><Link className='nav-link text-dark' to="/about">About Us</Link></li>
            <li className="nav-item dropdown ps-2" onClick={handleServicesClick}>
              <a className="nav-link text-dark dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                Services
              </a>
              <ul id='dropdown' className="dropdown-menu">
                {services.map(service => {
                  return (
                    <li className='ps-0' key={service.id}>
                      <Link className='dropdown-item' to={`/services/${service.link}`} state={{ service }}>
                        {service.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </li>
            <li className="nav-item ps-2"><Link className='nav-link text-dark' to="/our-plans">Our Plans</Link></li>
            <li className="nav-item ps-2"><Link className='nav-link text-dark' to="/contact">Contact Us</Link></li>
          </ul>
        </div>
      </div>
    </nav>
  </>
);
}
