import React, { useEffect, useRef, useState } from 'react'
import ContactComponent from '../../components/Contact/ContactComponet';

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [navbarHeight, setNavbarHeight] = useState(0);
  const sectionRef = useRef(null);

  useEffect(() => {
    // Get the height of the navbar
    const navbar = document.querySelector('.navbar'); // Replace with your actual selector
    if (navbar) {
      const height = navbar.clientHeight;
      setNavbarHeight(height);
    }

    // Add margin-top to the section based on the navbar height
    if (sectionRef.current) {
      sectionRef.current.style.marginTop = `${navbarHeight}px`;
    }
  }, [navbarHeight]);

  return (
    <div ref={sectionRef}>
      <ContactComponent />
    </div>
  )
}
