import React, { useEffect, useState } from 'react';

export default function AboutComponent({ aboutus }) {
  let i = 0;
  let storedDarkMode = localStorage.getItem('darkMode');
const [darkMode, setDarkMode] = useState(!!JSON.parse(storedDarkMode));
useEffect(()=>{
    let switcher = document.getElementById('switcher');
    
    switcher.onclick = () => {
      setDarkMode((prevDarkMode) => {
        const newDarkMode = !prevDarkMode;
        return newDarkMode; // Return the new value
    });
  }

}, [darkMode])
  return (
    <>
      <div className='py-5' style={{ backgroundImage: 'url(/assets/images/about-us.webp)', height: '80vh', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className='container h-100 d-flex align-items-center'>
          <div>
            <h2 className='text-white text-center'>WHO WE ARE</h2>
            <p className='text-white text-center'>We are dedicated to delivering results-driven plans and strategies that help our clients achieve their goals and succeed in today's competitive market landscape.</p>
          </div>
        </div>
      </div>

      <div className='py-lg-5 bg-white'>
        <div className='container'>
            {aboutus.map((aboutus_content, index) => {
              i++;
              return (
                <div key={index} className='row'>
                  {i % 2 === 0 ? (
                    <>
                    {darkMode ? (
                      <div className='col-md-6 d-none d-md-block'>
                        <div className='text-center'>
                          <img className='w-50' src={`/assets/images/aboutus/${aboutus_content.img_dark}`} alt="Not found" />
                        </div>
                      </div>
                  ): 
                  <div className='col-md-6 d-none d-md-block'>
                    <div className='text-center'>
                      <img className='w-50' src={`/assets/images/aboutus/${aboutus_content.img_light}`} alt="Not found" />
                    </div>
                  </div>
                  }
                        
                      <div className='col-md-6 bg-danger text-center py-3 px-3 d-none d-md-block'>
                          <h2 className='text-black'>{aboutus_content.subtitle}</h2>
                          <p className='text-white'>{aboutus_content.description}</p>
                      </div>
                        
                      <div className='col-md-6 bg-danger text-center py-3 px-3 d-block d-md-none'>
                          <h2 className='text-black'>{aboutus_content.subtitle}</h2>
                          <p className='text-white'>{aboutus_content.description}</p>
                      </div>
                      {darkMode ? (
                      <div className='col-md-6 d-block d-md-none'>
                      <div className='text-center'>
                        <img className='w-50' src={`/assets/images/aboutus/${aboutus_content.img_dark}`} alt="Not found" />
                      </div>
                    </div>
                  ): 
                      <div className='col-md-6 d-block d-md-none'>
                        <div className='text-center'>
                          <img className='w-50' src={`/assets/images/aboutus/${aboutus_content.img_light}`} alt="Not found" />
                        </div>
                      </div>
                  }
                      
                    </>
                  ) : (
                    <>
                      <div className='col-md-6 bg-danger text-center py-3 px-3 d-none d-md-block'>
                          <h2 className='text-black'>{aboutus_content.subtitle}</h2>
                          <p className='text-white'>{aboutus_content.description}</p>
                      </div>
                      {darkMode ? (
                      <div className='col-md-6 d-none d-md-block'>
                        <div className='text-center'>
                          <img className='w-50' src={`/assets/images/aboutus/${aboutus_content.img_dark}`} alt="Not found" />
                        </div>
                      </div>
                  ): 
                  <div className='col-md-6 d-none d-md-block'>
                    <div className='text-center'>
                      <img className='w-50' src={`/assets/images/aboutus/${aboutus_content.img_light}`} alt="Not found" />
                    </div>
                  </div>
                  }

                        <div className='col-md-6 bg-danger text-center py-3 px-3 d-block d-md-none'>
                          <h2 className='text-black'>{aboutus_content.subtitle}</h2>
                          <p className='text-white'>{aboutus_content.description}</p>
                      </div>
                      {darkMode ? (
                      <div className='col-md-6 d-block d-md-none'>
                      <div className='text-center'>
                        <img className='w-50' src={`/assets/images/aboutus/${aboutus_content.img_dark}`} alt="Not found" />
                      </div>
                    </div>
                  ): 
                      <div className='col-md-6 d-block d-md-none'>
                        <div className='text-center'>
                          <img className='w-50' src={`/assets/images/aboutus/${aboutus_content.img_light}`} alt="Not found" />
                        </div>
                      </div>
                  }
                    </>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className='py-5 bg-white'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4 text-center'>
              <img className='w-25' src={`/assets/images/aboutus/1.webp`} alt='Mot found'></img>
              <h2 className='text-dark'>Honesty & Transparency</h2>
              <p className='text-center text-dark fs-6'>We'll never make any promises we can't keep, and we'll always keep you updated on our progress.​</p>
            </div>
            <div className='col-md-4 text-center'>
              <img className='w-25' src={`/assets/images/aboutus/2.webp`} alt='Mot found'></img>
              <h2 className='text-dark'>Result Oriented</h2>
              <p className='text-center text-dark fs-6'>We succeed when our clients do. Committed to effective marketing campaigns and real results.​</p>
            </div>
            <div className='col-md-4 text-center'>
              <img className='w-25' src={`/assets/images/aboutus/3.webp`} alt='Mot found'></img>
              <h2 className='text-dark'>Experts in our Field</h2>
              <p className='text-center text-dark fs-6'>Our experienced team excels in the latest marketing tech and strategies.​</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
