import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import AboutComponent from '../../components/About/AboutComponent';
import ClientsCarouselComponent from '../../components/Clients/ClientsCarouselComponent';

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [aboutus, setAboutUs] = useState([
        {
            id: 1,
            subtitle: "Our Mission",
            img_light: "mission-light.webp",
            img_dark: "mission-dark.webp",
            description: "Empower brands to achieve their goals and provide exceptional services, support, and expertise to help build a powerful online presence, and achieve sustainable growth."
        },
        {
            id: 2,
            subtitle: "Our Vision",
            img_light: "vision-light.webp",
            img_dark: "vision-dark.webp",
            description: "Our vision is simple yet powerful: it is to be recognized as the go-to agency for marketing, known for our exceptional services, innovative solutions, and unwavering commitment to our clients' success."
        }
  ]);

  const [clients, setClients] = useState([
    {
        id: 1,
        img: "hadramoot.webp"
    },
    {
        id: 2,
        img: "marca.webp"
    },
    {
        id: 3,
        img: "narratives.webp"
    },
    {
        id: 4,
        img: "saac.webp"
    },
    {
        id: 5,
        img: "yk.webp"
    },
    {
        id: 6,
        img: "sip.webp"
    },
    {
      id: 7,
      img: "hayal.webp"
    }
  ]);

  const [navbarHeight, setNavbarHeight] = useState(0);
  const sectionRef = useRef(null);

  useEffect(() => {
    // Get the height of the navbar
    const navbar = document.querySelector('.navbar'); // Replace with your actual selector
    if (navbar) {
      const height = navbar.clientHeight;
      setNavbarHeight(height);
    }

    // Add margin-top to the section based on the navbar height
    if (sectionRef.current) {
      sectionRef.current.style.marginTop = `${navbarHeight}px`;
    }
  }, [navbarHeight]);

  return (
    <div ref={sectionRef}>
      <AboutComponent aboutus={aboutus}/>
      <ClientsCarouselComponent clients={clients}/>
    </div>
  )
}
